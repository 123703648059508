.App {
    text-align: center;
}

.port-buttons {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-bottom: 20px;
}

.paint-all {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.port-buttons div {
    margin: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #43C0F3;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
}

.port-buttons div.active-filter {
    background-color: #C44851;
}

.modal {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.25s ease;
}

.modal.open {
    opacity: 1;
    pointer-events: all;
}

.full-img {
    position: absolute;
    max-height: 70%;
    max-width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    transition: all 0.25s ease-out;
}

.full-img.open {
    transform: translate(-50%, -50%) scale(1);
}

.modal p {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
}