.Card {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #f9f9f9;
    width: 300px;
    margin: 20px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.tape {
    z-index: 2;
    position: relative;
    top: 40px;
}

.tape-img {
    width: 150px;
    filter: drop-shadow( 1px 1px #333);
}

.Card-img {
    overflow: hidden;
    width: 250px;
    height: 250px;
    margin: 25px 25px 10px;
}

.image {
    object-fit: cover;
    transition: all 0.3s ease-out;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.image:hover {
    transform: scale(1.05);
}

.Card-des {
    margin: 0 25px 25px;
    text-align: left;
    font-family: 'Caveat', cursive;
    font-size: 25px;
    line-height: 1;
}

.instaIcon {
    z-index: 2;
    right: 10px;
    bottom: 10px;
}

.whole-card {
    position: relative;
    z-index: 0;
}