html {
    box-sizing: border-box;
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    ;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url(/images/background.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-x: hidden;
}

body.open {
    overflow: hidden;
}

main {
    height: 100vh;
    margin-top: 125px;
}

ul {
    list-style: none;
}