.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    box-shadow: 0 8px 6px -6px black;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
}

.title {
    font-family: 'Permanent Marker', cursive;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 3px;
}

.Header ul {
    display: flex;
    align-items: center;
}

.Header ul li {
    margin: 5px
}

@media (max-width: 768px) and (min-width: 1px) {
    .title {
        font-size: 20px;
    }
}